
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonItem, IonThumbnail, IonLabel, IonAvatar, IonFab, IonFabButton, IonIcon } from '@ionic/vue';
import { personAddOutline, shareSocialOutline, cafeOutline } from 'ionicons/icons';
import PageHeader from '@/components/PageHeader.vue'

export default  {
  name: 'TabItems',
  components: { IonContent, IonPage, IonGrid, IonRow, IonCol, IonItem, IonThumbnail, IonLabel, IonAvatar, IonFab, IonFabButton, IonIcon, PageHeader },
  setup() {
    return {
      personAddOutline, shareSocialOutline, cafeOutline,
    }
  }
}
