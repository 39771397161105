<template>
  <ion-page>
    <page-header />
    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row>
          <!-- new activities -->
          <ion-col size="12" size-md="6" size-xl="5" offset="0" offset-xl="1">
            <h2>Letzte Aktivitäten</h2>
            <ion-item lines="none">
              <ion-icon slot="start" :icon="shareSocialOutline"></ion-icon>
              <ion-label>
                <h3>Neue Freunde</h3>
                <p>John und Peter sind nun Freunde.</p>
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-icon slot="start" :icon="cafeOutline"></ion-icon>
              <ion-label>
                <h3>Neues Angebot</h3>
                <p>Peter hat ein neues Angebot eingestellt.</p>
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-icon slot="start" :icon="personAddOutline"></ion-icon>
              <ion-label>
                <h3>Netzwerk erweitert</h3>
                <p>Ein Freund von John hat sich registriert.</p>
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-icon slot="start" :icon="personAddOutline"></ion-icon>
              <ion-label>
                <h3>Netzwerk erweitert</h3>
                <p>Ein Freund von Peter hat sich registriert.</p>
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-icon slot="start" :icon="cafeOutline"></ion-icon>
              <ion-label>
                <h3>Neues Angebot</h3>
                <p>John hat ein neues Angebot eingestellt.</p>
              </ion-label>
            </ion-item>
          </ion-col>
          <!-- latest items -->
          <ion-col size="12" size-md="6" size-xl="5">
            <h2>Neue Angebote</h2>
            <ion-item v-for="i in 4" :key="i" lines="none">
              <ion-thumbnail slot="start">
                <img src="https://via.placeholder.com/100">
              </ion-thumbnail>
              <ion-label>
                <h3>Item Thumbnail {{ i }}</h3>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                <p>Adipisci facilis eius labore enim animi laudantium</p>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" size-md="6" size-xl="5" offset="0" offset-xl="1">
            <h2>In der Nähe</h2>
            <ion-item v-for="i in 4" :key="i" lines="none">
              <ion-thumbnail slot="start">
                <img src="https://via.placeholder.com/100">
              </ion-thumbnail>
              <ion-label>
                <h3>Item Thumbnail {{ i }}</h3>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                <p>Adipisci facilis eius labore enim animi laudantium</p>
              </ion-label>
            </ion-item>
          </ion-col>
          <ion-col size="12" size-md="6" size-xl="5">
            <h2>Offene Anfragen</h2>
            <ion-item v-for="i in 6" :key="i" lines="none">
              <ion-avatar slot="start">
                <img src="https://via.placeholder.com/100">
              </ion-avatar>
              <ion-label class="align-row">
                <h3>John Doe</h3>
                <p>Wallstreet {{ i }}, 12345 Berlin</p>
                <ion-fab vertical="center" horizontal="end" slot="fixed">
                  <ion-fab-button size="small">
                    <ion-icon :icon="personAddOutline" title="Freundesanfrage"></ion-icon>
                  </ion-fab-button>
                </ion-fab>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonItem, IonThumbnail, IonLabel, IonAvatar, IonFab, IonFabButton, IonIcon } from '@ionic/vue';
import { personAddOutline, shareSocialOutline, cafeOutline } from 'ionicons/icons';
import PageHeader from '@/components/PageHeader.vue'

export default  {
  name: 'TabItems',
  components: { IonContent, IonPage, IonGrid, IonRow, IonCol, IonItem, IonThumbnail, IonLabel, IonAvatar, IonFab, IonFabButton, IonIcon, PageHeader },
  setup() {
    return {
      personAddOutline, shareSocialOutline, cafeOutline,
    }
  }
}
</script>
